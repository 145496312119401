<template>
  <v-dialog v-model="dialog" max-width="400">
    <v-card>
      <v-card-title>
        <h4>Error</h4>
      </v-card-title>

      <v-card-text>
        <p class="dialog-text">
          Requested client ID does not have email address. Please make sure you
          store email address before creating user.
        </p>
        <div class="div d-flex mt-3">
          <v-spacer></v-spacer>

          <v-btn color="var(--primary)" dark @click="close()">OK </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['dialog'],
  methods: {
    agreeToCookies() {
      this.dialog = false
      this.$store.dispatch('setCookiePolicy', true)
    },
    close() {
      this.$emit('update:dialog', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-text {
  margin-bottom: 0;
}
</style>